<template>
  <div class="env-component-create">
    <v-stepper
      v-model="step"
      flat>
      <v-stepper-header>
        <v-stepper-step
          role="menuitem"
          :step="1"
          :editable="false"
          :class="{ 'v-stepper__step--passed': step > 1 }">
          {{ $t('general') }}
        </v-stepper-step>

        <v-stepper-step
          role="menuitem"
          :step="2"
          class="v-stepper__step--two"
          :editable="false">
          {{ $t('configuration') }}
        </v-stepper-step>
      </v-stepper-header>

      <CyDetails
        hide-delete
        :can-cancel="step === 2"
        :cancel-btn-text="$t('forms.back')"
        cancel-btn-icon="chevron_left"
        :loading="false"
        :on-cancel="() => { step = 1 }"
        :can-save="canSave"
        :on-save="nextBtnHandler"
        :saving="creating"
        :save-btn-text="step === 1 ? $t('forms.next') : $t('environment.component.add')"
        :save-btn-icon="step === 1 ? 'chevron_right' : ''"
        :class="`cy-details--step-${step}`">
        <template #details_formFullWidth>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <div
                v-if="!selectedStack"
                class="stack-selection space-x-6">
                <div class="stack-selection__text">
                  <h3
                    class="mb-4"
                    v-text="$t('selectStackTitle')"/>
                  <p v-text="$t('selectStackText')"/>
                </div>
                <div class="stack-list__wrapper">
                  <CyWizardServiceCatalog
                    v-model="selectedStack"
                    :has-create-stack-btn-visible="false"
                    hide-hidden-filter
                    hide-more-btn/>
                </div>
              </div>
              <v-container
                v-else
                fluid>
                <v-row
                  align="start"
                  no-gutters>
                  <v-col>
                    <h3 v-text="$t('untranslated.stack')"/>
                  </v-col>
                  <v-col
                    cols="8"
                    lg="5">
                    <p
                      class="subtitle grey--text text--darken-1 mb-2"
                      v-text="$t('untranslated.stack')"/>
                    <CyWizardServiceCard
                      aria-label="Selected stack"
                      hide-more-btn
                      :service="selectedStack.catalog"/>
                    <button
                      class="change-stack--btn mt-2"
                      @click="changeStack"
                      v-text="$t('changeStack')"/>
                    <p
                      class="subtitle grey--text text--darken-1 mt-6 mb-2"
                      v-text="$t('environment.useCase')"/>
                    <v-item-group
                      v-if="selectedStack && !selectedUsecaseCanonical"
                      v-model="selectedUsecaseCanonical"
                      :mandatory="!multipleUsecases"
                      role="list"
                      class="usecase-list">
                      <v-item
                        #default="{ toggle }"
                        v-for="[key, { name, description, cloud_provider }] in _.toPairs(stackConfig)"
                        :key="key"
                        :value="key"
                        role="listitem"
                        :aria-label="name">
                        <div
                          class="usecase-list__item"
                          @click="toggle">
                          <CyIconCredential
                            v-if="cloud_provider"
                            size="32"
                            :type="cloud_provider"/>
                          <div :class="{ 'ml-4': cloud_provider }">
                            <div
                              class="h6"
                              v-text="name || key"/>
                            <div v-text="description"/>
                          </div>
                        </div>
                      </v-item>
                    </v-item-group>
                    <div v-else-if="selectedStack && selectedUsecaseCanonical">
                      <div class="usecase-list__item usecase-list__item--selected">
                        <CyIconCredential
                          size="32"
                          :type="selectedUsecase.cloud_provider || selectedUsecase.key"/>
                        <div :class="{ 'ml-4': selectedUsecase.cloud_provider }">
                          <div
                            class="h6"
                            v-text="selectedUsecase.name || selectedUsecaseCanonical"/>
                          <div v-text="selectedUsecase.description"/>
                        </div>
                      </div>
                      <button
                        v-if="multipleUsecases"
                        class="change-usecase--btn"
                        @click="selectedUsecaseCanonical = null"
                        v-text="$t('changeUsecase')"/>
                    </div>
                  </v-col>
                  <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-divider class="my-8"/>
                  </v-col>
                  <v-col
                    cols="8"
                    lg="5">
                    <v-divider class="my-8"/>
                  </v-col>
                  <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
                </v-row>
                <v-row
                  align="start"
                  no-gutters>
                  <v-col>
                    <h3 v-text="$t('general')"/>
                  </v-col>
                  <v-col
                    cols="8"
                    lg="5">
                    <v-text-field
                      v-model.trim="$v.formData.name.$model"
                      :label="$t('environment.component.name')"
                      :error-messages="nameErrors"
                      required
                      class="required-field"
                      @blur="$v.formData.name.$touch()"/>
                    <v-textarea
                      v-model="$v.formData.description.$model"
                      :label="$t('forms.fieldDescription')"
                      rows="3"
                      auto-grow/>
                    <CyAlert
                      v-if="errors"
                      theme="error"
                      :content="errors"/>
                  </v-col>
                  <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content
              class="step-2"
              :step="2">
              <CyWizardStackForms
                v-if="(selectedStack && selectedUsecase) && hasFormsConfig"
                v-model="stackformsVars"
                :forms="selectedUsecase.forms"
                :stack-ref="selectedStack.catalog.ref"
                :environment-canonical="envCanonical"
                :component-canonical="$getSlug(formData.name)"
                :use-case="selectedUsecaseCanonical"
                class="stackforms"
                @validate="setConfigurationValidity($event)"/>

              <CyWizardEnvComponentConfig
                v-else-if="selectedStack && selectedUsecase"
                v-model="userConfigs"
                :service-canonical="_.get(selectedStack, 'canonical')"
                :component="$getSlug(formData.name)"
                :environment="envCanonical"
                :project-canonical="projectCanonical"
                :config="selectedUsecase"
                display-expanded
                relative-height
                class="non-stackforms-editor"
                @valid="setConfigurationValidity($event)"/>
            </v-stepper-content>
          </v-stepper-items>
        </template>
      </CyDetails>
    </v-stepper>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import CyDetails from '@/components/CyDetails.vue'
import CyWizardEnvComponentConfig from '@/components/CyWizardEnvComponentConfig.vue'
import CyWizardServiceCard from '@/components/CyWizardServiceCard.vue'
import CyWizardServiceCatalog from '@/components/CyWizardServiceCatalog.vue'
import CyWizardStackForms from '@/components/CyWizardStackForms.vue'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb } from '@/utils/helpers'
import * as yaml from 'js-yaml'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageEnvComponentCreate',
  components: {
    CyDetails,
    CyWizardEnvComponentConfig,
    CyWizardServiceCard,
    CyWizardServiceCatalog,
    CyWizardStackForms,
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical } = this
    return constructBreadcrumb(this.$options.name, this.$t('forms.btnCreate'), [
      {
        label: this.$t('Components'),
        name: 'environmentOverview',
        params: { projectCanonical, envCanonical },
      },
      {
        label: envCanonical,
        name: 'environment',
        params: { projectCanonical, envCanonical },
      },
      {
        label: this.$t('Environments'),
        name: 'environmentOverview',
        params: { projectCanonical, envCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('title'),
      description: {
        text: this.$t('environment.component.description'),
      },
    }
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selectedStack: null,
    selectedUsecaseCanonical: null,
    formData: {
      name: '',
      description: '',
    },
    creating: false,
    step: 1,
    stackformsVars: {},
    isStackConfigValid: false,
    userConfigs: {},
  }),
  validations: {
    formData: {
      name: {
        required,
        isNameValid: (name) => REGEX.COMPONENT_NAME.test(name),
      },
      description: {},
    },
  },
  computed: {
    ...mapState({
      configRepositoryErrors: (state) => state.organization.project.configRepository.errors.configRepository,
      pipelineErrors: (state) => state.organization.project.pipeline.errors.pipeline,
      stackConfigErrors: (state) => state.organization.stack.errors.stackConfig,
    }),
    ...mapState('organization/stack', {
      stackConfig: (state) => state.config,
    }),
    ...mapGetters('organization/project', [
      'project',
      'configRepositoryBranch',
      'configRepositoryCanonical',
    ]),
    componentCanonical () {
      return this.$getSlug(this.formData.name)
    },
    selectedUsecase () {
      return this.stackConfig[this.selectedUsecaseCanonical]
    },
    multipleUsecases () {
      return Object.keys(this.stackConfig).length > 1
    },
    nameErrors () {
      const { $dirty, required, isNameValid } = this.$v.formData.name
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!isNameValid && !_.isEmpty(this.formData.name)) errors.push(this.$t('environment.component.nameInvalid'))
      return errors
    },
    errors () {
      return [
        ...this.pipelineErrors,
        ...this.configRepositoryErrors,
        ...this.stackConfigErrors,
      ]
    },
    formIsValid () {
      return !this.$v.$invalid
    },
    canSave () {
      const { selectedStack, selectedUsecaseCanonical, formIsValid } = this
      return this.step === 1
        ? Boolean(selectedStack && selectedUsecaseCanonical && formIsValid)
        : this.isStackConfigValid
    },
    hasFormsConfig () {
      return !_.isEmpty(this.selectedUsecase?.forms)
    },
  },
  watch: {
    selectedStack (newStack) {
      if (newStack?.catalog) {
        this.formData.name = newStack.catalog.name
      }
    },
  },
  methods: {
    ...mapActions('organization/project', [
      'CREATE_ENV_COMPONENT',
      'GET_PROJECT',
    ]),
    ...mapActions('organization/project/pipeline', [
      'CREATE_PIPELINE',
      'UNPAUSE_PIPELINE',
    ]),
    ...mapActions('organization/project/configRepository', [
      'CREATE_CONFIG_REPOSITORY_FILES',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK_CONFIG',
    ]),
    ...mapMutations('organization/project', [
      'SET_PROJECT',
    ]),
    changeStack () {
      this.selectedStack = null
      this.selectedUsecaseCanonical = null
    },
    async nextBtnHandler () {
      if (this.step === 1) {
        const success = await this.refreshStackConfig()
        if (success) {
          this.step = 2
        }
        return
      }
      this.createEnvComponent()
    },
    async refreshStackConfig () {
      this.creating = true
      const query = [
        this.selectedUsecaseCanonical,
        this.projectCanonical,
        this.envCanonical,
      ]
      const noErrorsOccurred = await this.GET_STACK_CONFIG({ stackRef: this.selectedStack.catalog.ref, query })
      this.creating = false
      return noErrorsOccurred
    },
    async createEnvComponent () {
      const { formIsValid, selectedStack, formData, selectedUsecaseCanonical, stackformsVars, envCanonical, componentCanonical } = this

      if (!formIsValid) return

      this.creating = true

      if (!this.hasFormsConfig) {
        await this.pushConfigRepositoryFiles()
      }

      const noErrorsOccurred = await this.CREATE_ENV_COMPONENT({
        envCanonical,
        component: _.$snakeCaseKeys({
          ...formData,
          canonical: componentCanonical,
          serviceCatalogRef: selectedStack.catalog.ref,
          useCase: selectedUsecaseCanonical,
          vars: this.hasFormsConfig ? stackformsVars : undefined,
        }),
      })

      if (!this.hasFormsConfig && noErrorsOccurred) {
        await this.updateProjectPipelines()
        await this.GET_PROJECT({ projectCanonical: this.projectCanonical, envCanonical })
      }

      if (noErrorsOccurred) {
        await this.GET_PROJECT({ projectCanonical: this.projectCanonical, envCanonical })
        this.$router.push({ name: 'envComponentOverview', params: { componentCanonical } })
      } else {
        this.step = 1
      }

      this.creating = false
    },
    async pushConfigRepositoryFiles () {
      const { configRepositoryCanonical } = this
      if (!configRepositoryCanonical) return true

      const configObject = {
        configs: this.getUserConfigsWithDestination(this.userConfigs),
        branch: this.configRepositoryBranch,
      }

      await this.CREATE_CONFIG_REPOSITORY_FILES({ configRepositoryCanonical, configObject })
      await this.$nextTick()

      return _.isEmpty(this.configRepositoryErrors)
    },
    async updateProjectPipelines () {
      const pipeline = this.getPipeline()
      this.SET_PROJECT({ ...this.project, pipelines: [pipeline] })

      if (!await this.CREATE_PIPELINE({
        envCanonical: this.envCanonical,
        componentCanonical: this.componentCanonical,
        pipeline,
      })) return false
      return await this.UNPAUSE_PIPELINE({
        pipelineCanonical: pipeline.pipeline_name,
        envCanonical: this.envCanonical,
        componentCanonical: this.componentCanonical,
      })
    },
    getPipeline () {
      const { envCanonical } = this
      const pipelineName = `${this.projectCanonical}-${envCanonical}-${this.componentCanonical}`
      const pipelineConfig = !this.hasFormsConfig
        ? JSON.stringify(yaml.load(this.userConfigs.pipeline.pipeline.content))
        : null
      const variables =
        !this.hasFormsConfig
          ? this.userConfigs.pipeline.variables.content
          : null

      return {
        pipeline_name: pipelineName,
        ...(!this.hasFormsConfig ? { passed_config: pipelineConfig, yaml_vars: variables } : {}),
      }
    },
    getUserConfigsWithDestination (configs, array = []) {
      _.entries(configs).forEach(([key, value]) => {
        if (!_.isPlainObject(value)) return

        const { content, destination: path } = value
        path
          ? array.push({ content: btoa(unescape(encodeURIComponent(content))), path })
          : this.getUserConfigsWithDestination(configs[key], array)
      })

      return array
    },
    setConfigurationValidity (isValid) {
      this.isStackConfigValid = isValid
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Create new @:Component',
        any: 'Any',
        changeStack: 'Change stack',
        changeUsecase: 'Change use case',
        selectStackText: 'Select the stack to use for this component. The stack cannot be changed once the component is created.',
        selectStackTitle: 'Select stack',
      },
      es: {
        title: 'Crear nuevo @:Component',
        any: 'Cualquiera',
        changeStack: 'Cambiar stack',
        changeUsecase: 'Cambiar caso de uso',
        selectStackText: 'Seleccione un stack que se utilizará para este componente. El stack no se puede cambiar una vez creado el componente.',
        selectStackTitle: 'Seleccionar stack',
      },
      fr: {
        title: 'Créer un nouveau @:Component',
        any: 'Tous',
        changeStack: 'Changer de stack',
        changeUsecase: `Changer de cas d'usage`,
        selectStackText: 'Sélectionnez la stack à utiliser pour ce composant. La stack ne peut pas être modifiée une fois le composant créé.',
        selectStackTitle: 'Sélectionner une stack',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .cy-details__content {
  padding-bottom: 0;
}

.env-component-create {
  .cy-details--step-2 {
    ::v-deep .cy-details__content {
      margin-inline: -32px;
      background-color: cy-get-color("white");
    }
  }

  .stack-list__wrapper {
    flex-grow: 1;
    padding: 0;
    padding-right: 8px;

    .stack-filters {
      display: flex;

      &__type,
      &__provider {
        width: 200px;
        max-width: 200px;
      }
    }
  }

  .stack-selection {
    display: flex;
    max-width: 100%;
    padding-right: 8px;
    padding-bottom: 86px;

    &__text {
      flex-basis: 350px;
      flex-shrink: 0;
    }

    &__list {
      flex-grow: 1;
      padding: 0;
      list-style-type: none;
    }

    &__loading {
      display: flex;
      flex-direction: column;
      width: 100%;

      .sk-template {
        width: 100%;
      }
    }
  }

  .change-stack--btn,
  .change-usecase--btn {
    color: cy-get-color("secondary");
    font-weight: $font-weight-bold;
    text-decoration: none;
    cursor: pointer;
  }

  .usecase-list {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      padding: 16px;
      border: 1px solid cy-get-color("primary", "light-4");
      border-radius: 4px;
      background-color: cy-get-color("white");
      color: cy-get-color("primary");

      .cy-stack-avatar {
        align-self: flex-start;
      }

      &:not(.usecase-list__item--selected) {
        cursor: pointer;

        &:hover {
          background-color: cy-get-color("primary", "light-5");
        }
      }
    }
  }

  .non-stackforms-editor {
    height: calc(100vh - 290px);
  }
}

.v-list-item__avatar {
  .svg-inline--fa {
    width: 24px;
    height: 24px;
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 0.5);
  }
}

::v-deep .v-stepper {
  overflow: visible;
  background: transparent;

  &__header {
    height: 56px;
    margin: 0 -32px;
    padding: 0 24px;
    overflow-y: hidden;
    border-bottom: 1px solid cy-get-color("grey", "light-2");
    background: cy-get-color("white");
    box-shadow: none;
  }

  &__step__step {
    font-weight: $font-weight-bolder;
  }

  &__items {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    flex-direction: row;
    overflow: visible;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 24px 0 0;

    &.step-2 {
      padding: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow: visible;
  }

  &__step {
    position: relative;
    flex-grow: 1;
    padding: 16px 4px;

    &--active,
    &--passed {
      &::before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: cy-get-color("secondary", "light-1");
      }
    }

    &--inactive {
      &::before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: cy-get-color("grey");
      }
    }

    &--two {
      &::before {
        z-index: 1;
        left: -2px;
      }
    }
  }
}

::v-deep .sticky-footer__viewport {
  &.cy-details--step-2 {
    margin-bottom: 0;
  }
}

::v-deep .stack-forms {
  min-height: calc(100vh - 276px);
  border-top: none;
}
</style>
